import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { ButtonCSS } from 'components/elements/Buttons/Button'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    margin-bottom: 2rem;
    max-width: 400px;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file-button {
    ${ButtonCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
  }

  & .form-duuf-submit {
    ${ButtonCSS};

    &-disabled {
      opacity: 1;
    }
  }

  & .form-duuf-confirm {
    color: green;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

interface FormContactProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
}

const FormContact: React.FC<FormContactProps> = ({ fields }) => (
  <section>
    <div className="container py-5">
      <ParseContent content={fields.description || ''} />
      <StyledFormDuuf id={1} className="mt-5" generate={false}>
        <div className="row justify-content-center">
          <div className="col-9">
            <div className="row">
              <div className="col-7">
                <FormDuufGroup hideIds={[2]} />
              </div>
              <div className="col-5">
                <FormDuufGroup showIds={[2]} />
              </div>
            </div>
            <FormDuufSubmit />
          </div>
        </div>
      </StyledFormDuuf>
    </div>
  </section>
)

export default FormContact
